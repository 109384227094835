<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data(){
      return {
        roleId: JSON.parse(localStorage.getItem('user')).data.menu_role_id || 0,
        name: JSON.parse(localStorage.getItem('user')).data.name,
        partner_id: JSON.parse(localStorage.getItem('user')).data.id,
        email: JSON.parse(localStorage.getItem('user')).data.email,
        properties:'',
      }
    },
  methods:{
    getProperties(){
      this.$axios
      .post(this.$apiService + "api/v1/getProperties", {
        partner_id: this.partner_id,
        role_id: this.roleId,

      })
      .then(response => {
          if (response.data.data.length != 0) {
                this.properties = response.data.data;

                this.properties.forEach(item => {
                  this.property = item.total;
                });
              } else {
                this.property = 0;
              }
              this.properties = this.property;

              console.log(this.properties);
      })
      .catch(error => {
            console.log(error);
          });
    }
  },
  mounted(){
    this.getProperties();    
  }  
}
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">{{ title }}</h4>

        <div class="page-title-right">
          <!-- <b-breadcrumb :items="items" class="m-0"></b-breadcrumb> -->
          <span class="m-0"><b>{{ this.name }}</b></span><br>
          <span class="m-0"><a href="#">{{ this.properties }} Properties</a></span>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
